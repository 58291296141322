/** @jsx jsx */
import React from "react"
import { graphql } from "gatsby"
import { CookiesProvider } from "react-cookie"
import { jsx } from "theme-ui"
import {
  Container,
  Columns,
  ColumnItem,
  CustomLink,
  Layout,
  SEO,
} from "components"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const SecurityAdvisoriesPage = ({ data, pageContext }) => {
  const page = data.contentfulSecurityAdvisories

  const paragraphContainer = {
    paddingBottom: "1rem",
    marginBottom: "1rem",
    padding: "0 0 20px",
    "h2, h3, h4, h5": {
      marginBottom: "1.5rem",
      marginTop: "52px",
      "&:first-of-type": {
        marginTop: "16px",
      },
    },
    p: {
      fontSize: "16px",
      color: "black",
      lineHeight: "140%",
      marginBottom: "1rem",
    },
    "td p": {
      marginBottom: 0,
    },
  }

  const options = {
    renderNode: {
      [BLOCKS.TABLE]: (node, children) => (
        <table
          style={{
            borderCollapse: "collapse",
            tableLayout: "fixed",
            verticalAlign: "baseline",
            maxWidth: "670px",
          }}
        >
          <tbody>{children}</tbody>
        </table>
      ),
      [BLOCKS.TABLE_CELL]: (node, children) => (
        <td
          style={{
            borderBottom: "1px solid #d7d7dc",
            padding: "13.6px",
            maxWidth: "32em",
          }}
        >
          {children}
        </td>
      ),
    },
  }

  const bodyOutput = page?.body ? renderRichText(page.body, options) : "";
  return (
    <CookiesProvider>
      <Layout pageContext={pageContext}>
        <SEO title={page.title} />
        <Container className="security-advisory">
          <Columns>
            <ColumnItem width="75">
              <h1 sx={{ marginTop: "3rem" }}>{page.title}</h1>
            </ColumnItem>
          </Columns>

          <Columns>
            <ColumnItem overrideStyles={{ paddingBottom: "4px" }}>
              <CustomLink
                href="/security-advisories"
                overrideStyles={{ marginBottom: 0 }}
              >
                View all security advisories
              </CustomLink>
            </ColumnItem>
          </Columns>
          {page.body && <div sx={paragraphContainer}>{bodyOutput}</div>}
        </Container>
      </Layout>
    </CookiesProvider>
  )
}

export const pageQuery = graphql`
  query advisories($id: String) {
    contentfulSecurityAdvisories(id: { eq: $id }) {
      id
      title
      url
      datePosted
      body {
        raw
      }
      shortDescription {
        shortDescription
      }
    }
  }
`

export default SecurityAdvisoriesPage
